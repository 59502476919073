





























import { Component, Vue } from "vue-property-decorator";
import DashTotalTipoCitasModule from "@/store/modules/Dashboards/Empresarial/DashTotalTipoCitas-module";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/pie";
import { DashColors } from "@/views/Dashboards/Dashcolors";
import { UtilsString } from '@/utils/utils-string';

@Component({
  components: {
    ECharts: () => import("vue-echarts/components/ECharts.vue"),
    CitasPendietesRealizadas: () =>
      import("../../Citas-Pendientes-Realizdas.vue"),
    CitasPendietesVsRealizadas: () =>
      import("../../Citas-PendientesVsRealizadas.vue")
  }
})
export default class ChartTiposDeCita extends Vue {
  public created() {}
  public get legend() {
    let lellenda: string[] = [];
    try {
      for (let l = 0; l < this.source.length; l++) {
        const element = this.source[l];
        lellenda.push(UtilsString.FristLetterMayu(element.tipo));
      }
    } catch (error) {}
    return lellenda;
  }
  public get source() {
    return DashTotalTipoCitasModule.DashTotalTipoCitas
      .datos_agrupados_por_porcentajes;
  }
  public get transformdata() {
    let dat = [];
    try {
      for (let i = 0; i < this.source.length; i++) {
        const element = this.source[i];
        dat.push({ value: element.total, name: UtilsString.FristLetterMayu(element.tipo) });
      }
    } catch (error) {}

    return dat;
  }
  public get total_realizadas() {
    let tot = 0;
    try {
      for (let i = 0; i < this.source.length; i++) {
        const element = this.source[i];
        if (element.tipo !== "Cita cancelada") {
          tot += element.total;
        }
      }
    } catch (error) {}

    return tot;
  }

  public get total_canceladas() {
    let tot = 0;
    for (let i = 0; i < this.source.length; i++) {
      const element = this.source[i];
      if (element.tipo.toLowerCase() === "Cita cancelada".toLowerCase()) {
        tot += element.total;
      }
    }
    return tot;
  }

  public get calcdecada() {
    //2 de cada 10 citas són canceladas.
    return Math.ceil((this.total_canceladas * 10) / this.total_realizadas);
  }

  public get datapie() {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{a} <br/>{b} : {c} ({d}%)"
      },
      legend: {
        orient: "vertical",
        left: "left",
        data: this.legend
      },
      series: [
        {
          name: "Agenda",
          type: "pie",
          radius: "55%",
          center: ["50%", "60%"],
          color: DashColors.Ordencolors2,
          data: this.transformdata,
          itemStyle: {
            emphasis: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)"
            }
          }
        }
      ]
    };
  }
}
